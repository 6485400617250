import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LawArticle } from './law-article';
import { LegacyLawArticleService } from './legacy/legacy-law-article.service';

export type LawArticleSort = 'SCORE' | 'DATE';

export interface LawArticleParams {
  filters?: LawArticleFilters;
  pageIndex?: number;
  pageSize?: number;
  sort?: LawArticleSort | null;
}

export interface LawArticleFilters {
  query?: string | null;
  date?: {
    start?: Date | null;
    end?: Date | null;
  };
  legalCode?: string | null;
}

export type LawArticleSearchResult = Omit<LawArticle, 'content' | 'versions'>;

export interface LawArticleSearchResults {
  data: LawArticleSearchResult[];
  duration: number;
  total: number;
}

@Injectable({
  providedIn: 'root',
  useExisting: LegacyLawArticleService,
})
export abstract class LawArticleService {
  abstract all(params?: LawArticleParams): Observable<LawArticleSearchResults>;
  abstract find(id: string): Observable<LawArticle>;
}
