export function createLawArticleTitle(
  legalCodeName: string,
  articleNumber?: string | null,
): string {
  if (!articleNumber) {
    return legalCodeName;
  }

  const legalCodeNameLowerCased = legalCodeName.toLocaleLowerCase();
  let preposition = 'du ';

  if (
    legalCodeNameLowerCased.includes('arrêté') ||
    legalCodeNameLowerCased.includes('ordonnance')
  ) {
    preposition = "de l'";
  } else if (
    legalCodeNameLowerCased.includes('loi') ||
    legalCodeNameLowerCased.includes('déclaration')
  ) {
    preposition = 'de la ';
  }
  return `Article ${articleNumber} ${preposition}${legalCodeName}`;
}
